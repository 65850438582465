import "@fontsource/montserrat";
import "@fontsource/montserrat/600.css";
import "./src/css/index.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SetCookie from "src/utils/SetCookie.js";

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }

  const codeBlocks = document.querySelectorAll('.code'); // Replace with the appropriate selector

  codeBlocks.forEach((codeBlock) => {
    // get any script tags inside the code block
    const code = codeBlock.querySelector('script[type="text/javascript"]').innerHTML;
    try {
      setTimeout(() => {
        new Function(code)(); // Execute the JavaScript code
      }, 1000);
    } catch (error) {
      console.error('Error executing JavaScript code:', error);
    }
  });
}

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/knowledge-centre/`]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    return false
  }
}

